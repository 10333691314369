<template>
  <div class="mx-28">
    <div class="mt-5 shadow-lg p-10">
      <!--<img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
      <div class="flex justify-start">
        <!--<img
          src="@/assets/img/Logo_JuanaBonita.svg"
          alt=""
          class="w-24 h-24 md:w-48 md:h-auto"
        />-->
        <img
          src="@/assets/img/productora.png"
          alt=""
          class="w-24 h-24 md:w-48 md:h-auto"
        />
      </div>
      <br />
      <br />
      <h1 class="font-bold text-center">
        POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN
      </h1>
      <br />

      <div>
        <p>EMPRESA: EMPRESA: PRODUCTORA FLÓREZ Y CIA SAS</p>
        <p>Nit: : 901.437.113-9</p>
        <p>
          Dirección: Calle 76 # 45 A 166 Int 201.
        </p>
        
        <p>Correo: Contacto@juanabonita.com.co</p>
      </div>
      <br />
      <div>
        <h1 class="font-bold text-center">
          TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS
        </h1>
        <br />
        <p>
          La información suministrada por el Titular será incluida en nuestra base de datos y será utilizada para las finalidades específicas detalladas a continuación:
        </p>
        <br />
        <div class="text-justify">
          <p>
            • Dar cumplimiento a las obligaciones contraídas por PRODUCTORA FLÓREZ Y CIA SAS
            con el Titular de la Información, contractual o extracontractualmente.
          </p>
          <p>
            • Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus empleados y ex empleados, de
            acuerdo con los lineamientos que determinan las leyes y/o decretos
            que regulan la materia.
          </p>
          <p>
            • Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, publicidad.
          </p>
          <p>
            • Publicidad y prospección comercial - Venta a distancia
            Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, publicidad y ventas a distancia.
          </p>
          <p>
            • Publicidad y prospección comercial - Prospección comercial
            Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, publicidad.
          </p>
          <p>
            • Publicidad y prospección comercial - Análisis de perfiles
            Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, publicidad.
          </p>
          <p>
            • Gestión contable, fiscal y administrativa - Gestión de facturación
            Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, efectos contables y financieros.
          </p>
          <p>
            • Gestión contable, fiscal y administrativa - Gestión de cobros y
            pagos Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, publicidad.
          </p>
          <p>
            • Gestión contable, fiscal y administrativa - Gestión de clientes
            Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, efectos contables y financieros.
          </p>
          <p>
            • Gestión Técnica y Administrativa – Envío de comunicaciones
            Cumplimiento de las obligaciones derivadas de las relaciones
            contractuales existentes con sus clientes activos o inactivos, de
            acuerdo con los lineamientos que determinan las leyes o decretos que
            regulen la materia, efectos contables y financieros.
          </p>
          <p>
            • Finalidades varias - Fidelización de clientes Cumplimiento de las
            obligaciones derivadas de las relaciones contractuales existentes
            con sus clientes activos o inactivos, de acuerdo con los
            lineamientos que determinan las leyes o decretos que regulen la
            materia, publicidad.
          </p>
          <p>
            • Gestión contable, fiscal y administrativa - Gestión de cobros y
            pagos Para el cumplimiento de las obligaciones legales que
            involucren datos personales con sus proveedores que tenga o haya
            tenido relaciones comerciales de acuerdo con los lineamientos que
            determinan las leyes o decretos que regulen la materia.
          </p>
          <p>
            • Gestión contable, fiscal y administrativa - Gestión de proveedores
            y contratistas Suministrar información a las áreas encargadas.
          </p>
          <p>• Las demás finalidades acorde a la Ley.</p>
          <br />
        </div>

        <div class="text-justify">
          <p>
            PRODUCTORA FLÓREZ Y CIA SAS Como responsable de las Bases de Datos
            privadas las cuales serán administradas de forma manual y/o
            sistemática que utiliza para sus efectos comerciales y laborales,
            declara a sus grupos de interés su Política de Tratamiento Datos
            Personales, la cual se desarrollará a través de la norma que da
            cumplimiento al régimen de protección de datos personales,
            consagrado en ley 1581 de 2012 y que estarán permanentemente
            disponibles en el sitio web https://productora.juanabonita.com.co

          </p>
          <br />
          <p>
            Los datos personales que custodia  PRODUCTORA FLÓREZ Y CIA, en
            su condición de responsable y/o encargado, serán tratados cumpliendo
            los principios y regulaciones previstas en las leyes colombianas
            aplicables, las cuales están alineadas con las buenas prácticas
            internacionales en la materia.
          </p>
          <br />
          <p>
            Tales datos personales serán tratados por r PRODUCTORA FLÓREZ Y CIA
            S.A.S. conforme las siguientes finalidades de carácter general:
          </p>
        </div>

        <br />
        <div class="text-justify">
          <p>
            1. Clientes: Para el cumplimiento de las obligaciones derivadas de
            las relaciones contractuales existentes con sus clientes activos o
            inactivos, de acuerdo con los lineamientos que determinan las leyes o decretos que regulen la materia.
          </p>
          <br />
          <p>
            2. Empleados: Para el cumplimiento de las obligaciones derivadas de
            las relaciones contractuales existentes con sus empleados y ex
            empleados, de acuerdo con los lineamientos que determinan las leyes
            o decretos que regulen la materia.
          </p>
          <br />
          <p>
            3. Proveedores: Para el cumplimiento de las obligaciones legales que
            involucren datos personales con sus proveedores que tenga o haya
            tenido relaciones comerciales de acuerdo con los lineamientos que
            determinan las leyes o decretos que regulen la materia.
          </p>
        </div>
        <br />
        <div class="text-justify">
          <p>
            En cada proceso empresarial, los datos serán administrados conforme
            se haya informado a cada uno de sus actores con acogimiento a la Ley
            de Privacidad se informarán de forma previa las finalidades
            particulares, y si el tratamiento se efectuará por un encargado del
            mismo bajo la dirección de PRODUCTORA FLÓREZ Y CIA,
            certificamos que hemos constatado que el tratamiento dado por el
            encargado cuenta igualmente con las condiciones de seguridad y
            privacidad garantizadas por el responsable.
          </p>
          <br />
          <p>
            Cualquier persona natural o jurídica que haga parte de uno de los
            grupos antes mencionado en relación con tratamiento de sus datos
            personales tiene derecho a:
          </p>
          <br />
          <p>
            1. Ejercer su derecho de “Habeas Data” consistente en conocer,
            actualizar, rectificar, oponerse y cancelar las informaciones
            recogidas de ellas.
          </p>
          <br />
          <p>2. Evidenciar la existencia del Consentimiento Otorgado.</p>
          <br />
          <p>
            3. Ejercer las acciones que la ley colombiana dispone dentro del
            marco jurídico que rige la materia de administración y privacidad de
            bases de datos.
          </p>
          <br />
          <p>
            Para el ejercicio del “Habeas Data”, el Titular del Dato Personal o
            su causahabientes que demuestre un legítimo interés conforme lo
            señalado en la normatividad vigente, podrá hacerlo a través del
            correo electrónico: contacto@juanabonita.com.co o dirigiendo una
            comunicación a la siguiente dirección física: Calle 57 Sur 48-24
            interiores 103-104 Sabaneta, Antioquia.
          </p>
          <br />
          <p>
            Para suministrar la información requerida amparada en el derecho de
            la consulta consagrada en la ley de “Habeas Data”, el solicitante
            debe suministrar con veracidad los datos donde podrá ser contactado
            en virtud de poder entregar y dar trámite a la solicitud.
          </p>
          <br />
          <p>
            Recibida la solicitud de ejercicio de “Habeas Data”,PRODUCTORA FLÓREZ Y CIA.
            dará respuesta a las consultas y/o reclamaciones
            conforme lo indicado en los artículos 14 y 15 de la Ley 1581 de
            2012.
          </p>
          <br />
          <p>
            El tratamiento de los datos personales que realiza PRODUCTORA FLÓREZ Y CIA 
            se hará conforme a la normatividad interna que se
            adopte para el cumplimiento de la legislación aplicable.
          </p>
          <br />
          <p>
            Esta política ha sido aprobada por el Representante Legal de
            PRODUCTORA FLÓREZ Y CIA el veinte de marzo de 2024.

          </p>
        </div>
        <br />
        <br />
      </div>
      <div>
        <h1 class="font-bold text-center">
          POLÍTICA DE PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES DE LOS
          TITULARES PRODUCTORA FLÓREZ Y CIA SAS
        </h1>
      </div>
      <br />
      <div class="text-justify">
        <br />
        <p>
          Dando cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012 y
          a su Decreto Reglamentario 1377 de 2013 y demás normas vigentes,
          PRODUCTORA FLÓREZ Y CIA adopta la presente política para el
          tratamiento de datos personales, la cual será informada a todos los
          Titulares de los datos recolectados actualmente y los que recolecte a
          futuro se obtengan en el ejercicio de las actividades comerciales y/o
          laborales. De esta manera, PRODUCTORA FLÓREZ Y CIA manifiesta
          que garantiza los derechos de la privacidad, la intimidad, el buen
          nombre, en el tratamiento de los datos personales, y en consecuencia
          todas sus actuaciones se regirán por los principios de legalidad,
          finalidad, libertad, veracidad, calidad, transparencia, acceso,
          circulación restringida, seguridad y confidencialidad. Todas las
          personas naturales o jurídicas que en desarrollo actividades
          comerciales y/o laborales, entre otras, sean permanentes u
          ocasionales, llegarán a suministrar a PRODUCTORA FLÓREZ Y CIA
          cualquier tipo de información o dato personal, podrá conocerla,
          actualizarla, rectificarla y/o suprimirla.
        </p>
        <br />
        <p>
          <span class="font-bold"
            >I. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO</span
          >
          <br />
          <br />
          Que en adelante se denominará PRODUCTORA FLÓREZ Y CIA en virtud
          de su condición de confeccionador y comercializador de prendas de
          vestir femenina y masculina.
        </p>
        <br />
        <p>
          DOMICILIO Y DIRECCIÓN: PRODUCTORA FLÓREZ Y CIA tiene su domicilio en la Ciudad de 
          Itagüí Calle 76 # 45 A 166 Int 201 contacto@juanabonita.com.co Teléfono: (57) 448 76 96.
        </p>
        <br />
        <p><span class="font-bold">II. MARCO LEGAL</span></p>
        <br />
        <p>
          Constitución Política, artículo 15. Ley 1266 de 2008 Ley 1581 de 2012
          Decretos
        </p>
        <p>
          Reglamentarios 1727 de 2009 y 2952 de 2010, Decreto Reglamentario
          parcial 1377 de 2013 Sentencias C – 1011 de 2008, y C – 748 del 2011,
          de la Corte Constitucional.
        </p>
        <br />
        <p><span class="font-bold">III. ÁMBITO DE APLICACIÓN</span></p>
        <br />
        <p>
          La presente política será aplicable a los datos personales registrados
          en cualquier base de datos de PRODUCTORA FLÓREZ Y CIA cuyo
          Titular sea una persona natural o jurídica, de naturaleza pública o
          privada.
        </p>
        <br />
        <p><span class="font-bold">IV. DEFINICIONES</span></p>
        <br />
        <p>
          Para los efectos de la presente política y en concordancia con la
          normatividad vigente en materia de protección de datos personales, se
          tendrán en cuenta las siguientes definiciones:
        </p>
        <br />
        <p>
          Autorización: aceptación expresa otorgada por el titular una vez
          conocido el tratamiento que se le dará a los datos personales
          suministrados.
        </p>
        <br />
        <p>
          Tratamiento: al autorizar el manejo de los datos el Titular permite:
          que consultemos los datos en centrales de riesgo, así mismo que los
          suministremos a empresas que nos presten servicios acordes con nuestro
          objeto social y con el objetivo único de cumplir con nuestro objeto
          social como: agencias de cobranza, centrales de riesgo, operadores de
          recaudo, trasportadores y todas aquellas que sean necesarias para el
          normal desarrollo de nuestra relación comercial.
        </p>
        <br />
        <p>
          Aviso de privacidad: Comunicación verbal o escrita generada por el
          responsable, dirigida al Titular para el tratamiento de sus datos
          personales, mediante la cual se le informa acerca de la existencia de
          las políticas de tratamiento de información que le serán aplicables,
          la forma de acceder a las mismas y las finalidades del tratamiento que
          se pretende dar a los datos personales.
        </p>
        <br />
        <p>
          Base de Datos: Conjunto organizado de datos personales que sea objeto
          de tratamiento.
        </p>
        <br />
        <p>
          Causahabiente: persona que ha sucedido a otra por causa del
          fallecimiento de ésta (heredero).
        </p>
        <br />
        <p>
          Dato personal: Cualquier información vinculada o que pueda asociarse a
          una o varias personas naturales determinadas o determinables.
        </p>
        <br />
        <p>
          Dato público: Es el dato que no sea semiprivado, privado o sensible.
          Son considerados datos públicos, entre otros, los datos relativos al
          estado civil de las personas, a su profesión u oficio y a su calidad
          de comerciante o de servidor público. Por su naturaleza, los datos
          públicos pueden estar contenidos, entre otros, en registros públicos,
          documentos públicos, gacetas y boletines oficiales y sentencias
          judiciales debidamente ejecutoriadas que no estén sometidas a reserva.
        </p>
        <br />
        <p>
          Datos sensibles: Se entiende por datos sensibles aquellos que afectan
          la intimidad del Titular o cuyo uso indebido puede generar su
          discriminación, tales como que revelen el origen racial o étnico, la
          orientación política, las convicciones religiosas o filosóficas, la
          pertenencia a sindicatos, organizaciones sociales, de derechos humanos
          o que promueva intereses de cualquier partido político o que
          garanticen los derechos y garantías de partidos políticos de
          oposición, así como los datos relativos a la salud, a la vida sexual,
          y los datos biométricos.
        </p>
        <br />
        <p>
          Encargado del Tratamiento: Persona natural o jurídica, pública o
          privada, que para cumplimiento de la relación existente, realiza el
          Tratamiento de datos personales por cuenta del Responsable del
          Tratamiento.
        </p>
        <br />
        <p>
          Responsable del Tratamiento: Persona natural o jurídica, pública o
          privada, que por sí misma o en asocio con otros, decida sobre la base
          de datos y/o el tratamiento de los datos.
        </p>
        <br />
        <p>
          Titular: Persona natural cuyos datos personales sean suministrados
          para iniciar cualquier tipo de relación con el Responsable y que ha
          conocido los derechos de privacidad de sus datos personales.
        </p>
        <br />
        <p>
          Tratamiento: Cualquier operación o conjunto de operaciones sobre datos
          personales, tales como la recolección, almacenamiento, uso,
          circulación o supresión.
        </p>
        <br />
        <p>
          Transferencia: la transferencia de datos tiene lugar cuando el
          responsable y/o encargado del tratamiento de datos personales, envía
          la información o los datos personales a un receptor, que a su vez es
          responsable del tratamiento y se encuentra dentro o fuera del país.
        </p>
        <br />
        <p>
          Transmisión: tratamiento de datos personales que implica la
          comunicación de los mismos dentro o fuera del territorio de la
          República de Colombia cuando tenga por objeto la realización de un
          tratamiento por el encargado por cuenta del responsable, para el caso
          nuestro será transmitida a agencias de cobranza que nos garanticen que
          la política de privacidad será respetada y conservada acorde con las
          políticas nuestras.
        </p>
        <br />
        <p><span class="font-bold">V. PRINCIPIOS</span></p>
        <br />
        <p>
          Para efectos de garantizar la protección de datos personales,
          PRODUCTORA FLÓREZ Y CIA  aplicará de manera armónica e integral
          los siguientes principios, a la luz de los cuales se deberá realizar
          el tratamiento, transferencia y transmisión de datos personales.
        </p>
        <br />
        <p>
          Principio de legalidad en materia de Tratamiento de datos: El
          tratamiento de datos es una actividad regulada, la cual deberá estar
          sujeta a las disposiciones legales vigentes y aplicables que rigen el
          tema.
        </p>
        <br />
        <p>
          Principio de finalidad: la actividad del tratamiento de datos
          personales que realice PRODUCTORA FLÓREZ Y CIA  o a la cual
          tuviere acceso, obedecerán a una finalidad legítima en consonancia con
          la Constitución Política de Colombia, la cual deberá ser informada al
          respectivo titular de los datos personales.
        </p>
        <br />
        <p>
          Principio de libertad: el tratamiento de los datos personales sólo
          puede realizarse con el consentimiento, previo, expreso e informado
          del Titular. Los datos personales no podrán ser obtenidos o divulgados
          sin previa autorización, o en ausencia de mandato legal, estatutario,
          o judicial que releve el consentimiento.
        </p>
        <br />
        <p>
          Principio de veracidad o calidad: la información sujeta a Tratamiento
          de datos personales debe ser veraz, completa, exacta, actualizada,
          comprobable y comprensible. Se prohíbe el Tratamiento de datos
          parciales, incompletos, fraccionados o que induzcan a error.
        </p>
        <br />
        <p>
          Principio de transparencia: En el tratamiento de datos personales,
          PRODUCTORA FLÓREZ Y CIA  garantizará al Titular su derecho de
          obtener en cualquier momento y sin restricciones, información acerca
          de la existencia de cualquier tipo de información o dato personal que
          sea de su interés o titularidad.
        </p>
        <br />
        <p>
          Principio de acceso y circulación restringida: El tratamiento de datos
          personales se sujeta a los límites que se derivan de la naturaleza de
          éstos, de las disposiciones de la ley y la Constitución. En
          consecuencia, el tratamiento sólo podrá hacerse por personas
          autorizadas por el titular y/o por las personas previstas en la ley.
          Los datos personales, salvo la información pública, no podrán estar
          disponibles en internet u otros medios de divulgación o comunicación
          masiva, salvo que el acceso sea técnicamente controlable para brindar
          un conocimiento restringido sólo a los titulares o terceros
          autorizados conforme a la ley. Para estos propósitos la obligación de
          PRODUCTORA FLÓREZ Y CIA , será de medio.
        </p>
        <br />
        <p>
          Principio de seguridad: la información sujeta a tratamiento por
          PRODUCTORA FLÓREZ Y CIA , se deberá manejar con las medidas
          técnicas, humanas y administrativas que sean necesarias para otorgar
          seguridad a los registros evitando su adulteración, pérdida, consulta,
          uso o acceso no autorizado o fraudulento.
        </p>
        <br />
        <p>
          Principio de confidencialidad: Todas las personas que en PRODUCTORA FLÓREZ Y CIA ,
          administren, manejen, actualicen o tengan acceso a
          informaciones de cualquier tipo que se encuentre en Bases de Datos,
          están obligadas a garantizar la reserva de la información, por lo que
          se comprometen a conservar y mantener de manera estrictamente
          confidencial y no revelar a terceros, toda la información que llegaren
          a conocer en la ejecución y ejercicio de sus funciones; salvo cuando
          se trate de actividades autorizadas expresamente por la ley de
          protección de datos. Esta obligación persiste y se mantendrá inclusive
          después de finalizada su relación con alguna de las labores que
          comprende el Tratamiento.
        </p>
        <br />
        <p>
          <span class="font-bold"
            >VI. DERECHOS DEL TITULAR DE LA INFORMACIÓN</span
          >
        </p>
        <br />
        <p>
          De acuerdo con lo contemplado por la normatividad vigente aplicable en
          materia de protección de datos, los siguientes son los derechos de los
          titulares.
        </p>
        <br />
        <p>
          1. A Acceder, conocer, actualizar y rectificar sus datos personales
          frente a PRODUCTORA FLÓREZ Y CIA en su condición de responsable
          del tratamiento. Este derecho se podrá ejercer, entre otros, frente a
          datos parciales, inexactos, incompletos, fraccionados, que induzcan a
          error, o aquellos cuyo tratamiento esté expresamente prohibido o no
          haya sido autorizado.
        </p>
        <p>
          2. A autorizar el diligenciamiento del formato de manejo de datos
          personales, los cuales serán conservados hasta que el titular o la
          empresa lo consideren.
        </p>
        <p>
          3. A que se informe cuál es el manejo que se le dará a sus datos y
          garantizarle la seguridad y privacidad de los mismos.
        </p>
        <p>
          4. A que se le garantice que sus datos personales no estarán
          disponibles en Redes Sociales o medios de comunicación.
        </p>
        <p>
          5. A que se le informe que hay una persona natural o jurídica
          encargada de acceder a su información.
        </p>
        <p>
          6. A que se les informe que algunos de sus datos se suministran a
          otras entidades con el sólo objetivo de cumplir con la relación
          comercial existente.
        </p>
        <p>
          7. A que se le suministren los datos del encargado de la información
          cuando estos sean transmitidos a otra persona natural o jurídica para
          el normal desarrollo de la relación comercial y/o contractual.
        </p>
        <p>
          8. A que se informe que la información recibida se conservará hasta
          que el Titular lo autorice.
        </p>
        <p>
          9. A que se le Suministre la información de cuál es el proceso, en
          caso que desee bloquear, corregir, actualizar o suprimir la
          información que reposa en las bases de datos.
        </p>
        <p>
          10. A que se le informe a quien se debe dirigir en caso que requiera:
          consultar, corregir, actualizar o suprimir la información.
        </p>
        <p>
          11. A que se Informen los canales por los cuales pueden solicitar
          información acerca de sus datos (Teléfono (57)4487696, departamento de
          Servicio al Cliente, Correo electrónico: Contacto@juanabonita.com.co
        </p>
        <p>
          12. A que se le Informe en caso de que se realice alguna modificación
          a la política de tratamiento de datos personales, lo cual estará
          disponible en la Página https://productora.juanabonita.com.co
        </p>
        <p>
          13. A conocer que su información se conserva en un lugar seguro y que
          nadie fuera de los relacionados como encargados de trámites la
          manipulan.
        </p>
        <p>
          14. A que se le informe que los datos relacionados en la solicitud de
          crédito no serán divulgados sin autorización.
        </p>
        <p>
          15. A Presentar ante la Superintendencia de Industria y Comercio, o la
          entidad que hiciere sus veces, quejas por infracciones a lo dispuesto
          en la ley 1581 de 2012 y las demás normas que la modifiquen, adicionen
          o complementen, previo trámite de consulta o requerimiento ante
          PRODUCTORA FLÓREZ Y CIA
        </p>
        <p>
          La atención de peticiones, consultas, quejas o reclamos se pueden
          comunicar al teléfono (57) 4487696 o al correo
          contacto@juanabonita.com.co
        </p>
        <br />
        <p>
          <span class="font-bold">DERECHOS DE LOS NIÑOS Y ADOLESCENTES</span>
        </p>
        <br />
        <p>
          1. Datos de menores de edad. PRODUCTORA FLÓREZ Y CIA. No
          reconocerá como CLIENTE a un niño, niña o adolescente <br />
          2. En caso de que PRODUCTORA FLÓREZ Y CIA. determine que un niño,
          niña o adolescente solicita ser su CLIENTE, rechazará de plano tal
          solicitud y eliminará de manera inmediata los datos por él
          suministrados.<br />
          3. No obstante MPRODUCTORA FLÓREZ Y CIA. reconocerá como otorgante
          de datos a adolescentes y podrá utilizar sus datos para efectos
          comerciales, pero NUNCA los pondrá a circular; cuando se efectué
          tratamiento de datos de menores, en los términos establecidos en este
          artículo se presume que sus padres han dado autorización y han emitido
          concepto favorable sobre la madurez que estos tienen en la opinión,
          escucha, autonomía y capacidad de determinar su percepción frente a
          los productos que produce PRODUCTORA FLÓREZ Y CIA.
        </p>
        <br />
        <br />
      </div>

      <div>
        <h1 class="font-bold text-center">
          POLÍTICAS PARA EL TRATAMIENTO DE DATOS PERSONALES PROVEEDORES
        </h1>
      </div>
      <div class="text-justify">
        <br />
        <br />
        <p>
          La Empresa PRODUCTORA FLÓREZ Y CIA. ubicada en la dirección Calle
          57 Sur 48-24 interiores 103-104 Sabaneta – Ant. Teléfono (57)4487696
          <br />
          TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS: <br />
          <span class="ml-5">
            <p>
              1. Verificación de Referencia <br />
              2. Suministro de referencias comerciales solicitados.
              <br />
              3. Inscripción al banco para pagos <br />
              4. Envió por cualquiera de los medios de comunicación existentes
              información sobre todo evento que se considere importante para las
              partes.
            </p>
          </span>
          <br />
        </p>
        <p>
          Confidencialidad de la información. EL PROVEEDOR, y PRODUCTORA FLÓREZ Y CIA.
          por la suscripción del contrato verbal o escrito,
          quedarán obligados a mantener bajo reserva, sin revelación a terceras
          personas y estrictamente confidencial, evitando por ende en que se
          haga de dominio público, toda la información que sea de propiedad de
          cualquiera de ellas y le haya sido suministrada o tenga conocimiento
          de ella con el objeto de desarrollar el contrato.
        </p>
        <br />
        <p>
          Por ende, EL PROVEEDOR, y PRODUCTORA FLÓREZ Y CIA. podrán hacer
          uso de ella, para la ejecución del objeto del contrato, debiendo
          proteger dicha información confidencial, con el fin de que no sea
          revelada y conocida por terceros, custodiándola con la diligencia y
          cuidado apropiados a las circunstancias; no obstante lo cual, podrá
          revelarla a las autoridades competentes para los fines y en los casos
          previstos expresamente en la ley, cuando ello sea necesario en
          cumplimiento de obligaciones y deberes legales y siempre y cuando
          medie orden judicial o de autoridad competente, pero en tal caso, Si
          alguna de las partes es requerida por autoridad legal competente,
          deberán informar del hecho a LA PARTE no requerida, indicando la
          autoridad a la cual será revelada la información confidencial, así
          como los motivos que justifican su proceder.
        </p>
        <br />
        <p>DERECHOS QUE LE ASISTE A LOS TITULARES DE LA INFORMACIÓN</p>
        <br />
        <p>
          <span>
            1. A autorizar el diligenciamiento del formato de manejo de datos
            personales. <br />
            2. A que se le garantice la seguridad de su información. <br />
            3. A que se le garantice que su información personal no va a ser
            pasada a terceros. <br />
            4. A que se le informe que los datos no serán divulgados sin
            autorización. <br />
            5. A que se le informe quien es la persona encargada de acceder a su
            información. <br />
            6. A que se informe cuál es el manejo que se le dará a sus datos y
            garantizarle la seguridad y privacidad de los mismos. <br />
            7. A conocer, actualizar y rectificar de manera gratuita los datos
            que se encuentren incorporados en nuestra base de datos.<br />
            8. A solicitarnos prueba de la autorización otorgada a PRODUCTORA FLÓREZ Y CIA.
             para custodiar y tratar sus datos, excepto cuando no
            se requiere tal autorización. <br />
            9. A que se Informen los canales por los cuales pueden solicitar
            información acerca de sus datos (Teléfono (57)4487696, departamento
            de Compras, Correo electrónico: Contacto@juanabonita.com.co <br />
            10. A suprimir el dato cuando a ello haya lugar. <br />
            11. A que se le Informe en caso de que se realice alguna
            modificación a la política de tratamiento de datos personales lo
            cual estará disponible en la Página https://productora.juanabonita.com.co <br />
          </span>
        </p>
        <br />
        <p>
          La atención de peticiones, consultas, quejas o reclamos se pueden
          comunicar al teléfono (57) 4487696 o al correo
          contacto@juanabonita.com.co.
        </p>
      </div>

      <br />
      <br />
      <div class="text-center font-bold">
        <h1>POLÍTICAS PARA EL TRATAMIENTO DE DATOS PERSONALES EMPLEADOS</h1>
      </div>

      <div class="text-justify">
        <br />
        <br />
        <p>
          Política de Tratamiento de la información regula el tratamiento, la
          recolección, el almacenamiento, el uso, la circulación y la supresión
          de aquella información quePRODUCTORA FLÓREZ Y CIA reciba de sus
          EMPLEADOS y de los ASPIRANTES en los distintos puntos de recolección
          de información; de acuerdo con las disposiciones contenidas en la Ley
          Estatutaria 1581 de 2012.
        </p>
        <br />
        <h2>TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS:</h2>
        <br />
        <p>
          1. Verificación de Referencia. <br />
          2. Suministro de certificados laborales. <br />
          3. Envío de información a EPS, Cajas de compensación, Fondo de
          Pensiones y Cesantías. <br />
          4. Inscripción al banco para pago de nómina. <br />
          5. Envió por cualquiera de los medios de comunicación existentes
          información sobre todo evento que se considere importante para las
          partes. <br />
        </p>
        <br />
        <h2>DERECHOS QUE LE ASISTEN AL EMPLEADO.</h2>
        <br />
        <p>
          1. A autorizar el diligenciamiento del formato de manejo de datos
          personales.<br />
          2. Garantizar a los empleados la privacidad de sus datos personales y
          el de su grupo.<br />
          3. Garantizar la privacidad de sus ingresos, embargos, descuentos de
          nómina y todos aquellos que sean necesarios para el cumplimiento de la
          relación contractual.<br />
          4. A que se les informe que durante la permanencia en las
          instalaciones de la organización son gravados permanentemente.<br />
          5. A que se les informe que algunos de sus datos se suministran a
          otras entidades como EPS, caja de compensación, cesantías, AFP, Banco,
          y todos aquellos que sean necesarios para el cumplimiento de la
          relación laboral.<br />
          6. A Solicitar prueba de la autorización otorgada a PRODUCTORA FLÓREZ Y CIA
          para custodiar y tratar sus datos, excepto cuando no
          se requiere tal autorización.<br />
          7. A que se les informe si el algún evento sus fotografías o videos
          van a ser subidos a redes.<br />
          8. A que se Informen los canales por los cuales pueden solicitar
          información acerca de sus datos (Teléfono (57) 4487696, departamento
          de Talento Humano, Correo electrónico: Contacto@juanabonita.com.co<br />
          9. A conocer, actualizar, rectificar y suprimir de manera gratuita los
          datos que se encuentren incorporados en la base de datos de
          PRODUCTORA FLÓREZ Y CIA ser informado, previa solicitud, del uso que se le ha dado a sus
          datos personales.<br />
          10. A Revocar expresamente y en cualquier tiempo, la autorización para
          el tratamiento de sus datos. Tal revocación no podrá ser solicitada
          cuando el empleado se encuentre trabajando actualmente.<br />
          11. A que se le Informe en caso de que se realice alguna modificación
          a la política de tratamiento de datos personales lo cual estará
          disponible en la Página https://productora.juanabonita.com.co/<br />
        </p>
        <br />
        <p>
          La atención de peticiones, consultas, quejas o reclamos se pueden
          comunicar al teléfono (57) 4487696 o al correo
          contacto@juanabonita.com.co
        </p>
        <br />

        <h2>
          <span class="font-bold"
            >VII. DEBERES DE PRODUCTORA FLÓREZ Y CIA COMO RESPONSABLE Y
            ENCARGADA DEL TRATAMIENTO DE LOS DATOS PERSONALES</span
          >
        </h2>
        <br />

        <p>
          PRODUCTORA FLÓREZ Y CIA reconoce la titularidad que de los datos
          personales ostentan las personas y en consecuencia ellas de manera
          exclusiva pueden decidir sobre los mismos. Por lo tanto, PRODUCTORA FLÓREZ Y CIA
          utilizará los datos personales para el cumplimiento de
          las finalidades autorizadas expresamente por el titular o por las
          normas vigentes.
        </p>
        <br />
        <p>
          En el tratamiento y protección de datos personales, PRODUCTORA FLÓREZ Y CIA
          tendrá los siguientes deberes, sin perjuicio de otros
          previstos en las disposiciones que regulen o lleguen a regular esta
          materia:
        </p>
        <br />
        <p>
          1. Garantizar al Titular, en todo tiempo, el pleno y efectivo
          ejercicio del derecho de hábeas data. <br />
          2. Solicitar y conservar, copia de la respectiva autorización otorgada
          por el titular para el tratamiento de datos. <br />
          3. Informar debidamente al Titular sobre la finalidad de la
          recolección y los derechos que le asisten en virtud de la autorización.
          <br />
          4. Conservar la información bajo las condiciones de seguridad
          necesarias para impedir su adulteración, pérdida, consulta, uso o
          acceso no autorizado.<br />
          5. Garantizar que la información sea veraz, completa, exacta,
          actualizada, comprobable.<br />
          6. Actualizar oportunamente la información, atendiendo de esta forma
          todas las novedades respecto de los datos del titular. Adicionalmente,
          se deberán implementar todas las medidas necesarias para que la
          información se mantenga segura. <br />
          7. Rectificar la información cuando sea incorrecta y comunicar lo
          pertinente. <br />
          8. Respetar las condiciones de seguridad y privacidad de la
          información del Titular. <br />
          9. Tramitar las consultas y reclamos formulados en los términos
          señalados por la la ley. <br />
          10. Identificar cuando determinada información se encuentra en
          discusión por parte del titular.<br />
          11. Informar a solicitud del Titular sobre el uso dado a sus datos. <br />
          12. Informar a la autoridad de protección de datos cuando se presenten
          violaciones a los códigos de seguridad y existan riesgos en la
          administración de la información de los datos. <br />
          13. Cumplir los requerimientos e instrucciones que imparta la
          Superintendencia de Industria y Comercio sobre el tema en
          particular.<br />
          14. Usar únicamente datos cuyo tratamiento esté previamente autorizado
          de conformidad con lo previsto en la ley 1581 de de 2012.<br />
          15. Velar por el uso adecuado de los datos personales de los niños,
          niñas y adolescentes, en aquellos casos en que se entra autorizado el
          tratamiento de sus datos.<br />
          16. Registrar en la base de datos las leyenda «reclamo en trámite» en
          la forma en que se regula en la ley.<br />
          17. Insertar en la base de datos la leyenda «información en discusión
          judicial» una vez notificado por parte de la autoridad competente
          sobre procesos judiciales relacionados con la calidad del dato
          personal.<br />
          18. Abstenerse de circular información que esté siendo controvertida
          por el titular y cuyo bloqueo haya sido ordenado por la
          Superintendencia de Industria y Comercio.<br />
          19. Permitir el acceso a la información únicamente a las personas que
          pueden tener acceso a ella de acuerdo a la ley.<br />
          20. Usar los datos personales del Titular sólo para aquellas
          finalidades para las que se encuentre facultada debidamente y
          respetando en todo caso la normatividad vigente sobre protección de
          datos personales.<br />
        </p>

        <br />

        <h2>
          <span class="font-bold"
            >VIII. AUTORIZACIÓN Y CONSENTIMIENTO DEL TITULAR</span
          >
        </h2>
        <br />
        <p>
          PRODUCTORA FLÓREZ Y CIA requiere del consentimiento libre,
          previo, expreso e informado del titular de los datos personales para
          el tratamiento de los mismos, exceptos en los casos expresamente
          autorizados en la ley.
        </p>
        <br />

        <p>
          Medios para otorgar la autorización PRODUCTORA FLÓREZ Y CIA
          obtendrá la autorización mediante diferentes medios, entre ellos el
          documento físico, electrónico, mensaje de datos, Internet, Sitios Web,
          o en cualquier otro formato que en todo caso permita la obtención del
          consentimiento mediante conductas inequívocas a través de las cuales
          se concluya que de no haberse surtido la misma por parte del titular o
          la persona legitimada para ello, los datos no se hubieran almacenado o
          capturado en la base de datos. La autorización será solicitada por
          PRODUCTORA FLÓREZ Y CIA de manera previa al tratamiento de los
          datos personales. Prueba de la autorización PRODUCTORA FLÓREZ Y CIA
          conservará la prueba de la autorización otorgada por los
          titulares de los datos personales para su tratamiento, para lo cual
          utilizará los mecanismos disponibles a su alcance en la actualidad al
          igual que adoptará las acciones necesarias para mantener el registro
          de la forma y fecha y en la que obtuvo ésta. En consecuencia
          PRODUCTORA FLÓREZ Y CIA podrá establecer archivos físicos o
          repositorios electrónicos realizados de manera directa o a través de
          terceros contratados para tal fin.
        </p>
        <br />
        <p>
          Revocatoria de la autorización: los titulares de los datos personales
          pueden en cualquier momento revocar la autorización otorgada a
          PRODUCTORA FLÓREZ Y CIA para el tratamiento de sus datos
          personales o solicitar la supresión de los mismos, siempre y cuando no
          lo impida una disposición legal o contractual. PRODUCTORA FLÓREZ Y CIA
          establecerá mecanismos sencillos y gratuitos que permitan al
          Titular revocar su autorización o solicitar la supresión sus datos
          personales, al menos por el mismo medio por el que lo otorgó. Para lo
          anterior, deberá tenerse en cuenta que la revocatoria del
          consentimiento puede expresarse, por una parte, de manera total en
          relación con las finalidades autorizadas, y por lo tanto PRODUCTORA FLÓREZ Y CIA
          deberá cesar cualquier actividad de tratamiento de los
          datos; y por la otra de manera parcial en relación con ciertos tipos
          de tratamiento, en cuyo caso serán estos sobre los que cesarán las
          actividades de tratamiento, como para fines publicitarios, entre
          otros. En este último caso, PRODUCTORA FLÓREZ Y CIA podrá
          continuar tratando los datos personales para aquellos fines en
          relación con los cuales el Titular no hubiera revocado su
          consentimiento.
        </p>
        <br />

        <h2>
          <span class="font-bold"
            >IX. TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS Y FINALIDAD DEL
            MISMO</span
          >
        </h2>
        <br />
        <p>
          El tratamiento de los datos personales de Clientes, Proveedores,
          Empleados, Clientes inactivos, ex proveedores y ex empleados, o de
          cualquier persona con la cual PRODUCTORA FLÓREZ Y CIA tuviere
          establecida o estableciera una relación, permanente u ocasional, lo
          realizará en el marco legal que regula la materia y en virtud de su
          condición de confeccionador y comercializador de prendas de ropa de
          vestir femenina y masculina, y serán todos los necesarios para el
          cumplimiento de la misión institucional. En todo caso, los datos
          personales podrán ser recolectados y tratados para:
        </p>
        <br />
        <p>
          1. Realizar el envío de información relacionada con: catálogos,
          productos. <br />
          2. Comercializar, nuevos productos, actividades comerciales,
          estrategias comerciales, programas de fidelización, estados de cuenta,
          comunicados sobre estados de la relación comercial, políticas que se
          adopten y afecten directamente la relación, actividades, noticias,
          contenidos por área de interés, y demás bienes ofrecidos por
          PRODUCTORA FLÓREZ Y CIA <br />
          3. Desarrollar la misión de PRODUCTORA FLÓREZ Y CIA<br />
          4. Conforme a sus estatutos.<br />
          5. Cumplir con la normatividad vigente en Colombia, entidades
          acreditadoras o las autoridades<br />
          6. Cumplir las normas aplicables a proveedores y contratistas,
          incluyendo pero sin limitarse a las tributarias y<br />
          7. Cumplir lo dispuesto por el ordenamiento jurídico colombiano en
          materia laboral y de seguridad social, entre otras, aplicables a ex
          empleados, empleados actuales y candidatos a futuro<br />
          8. Realizar encuestas relacionadas con los servicios o bienes de
          PRODUCTORA FLÓREZ Y CIA <br />
          9. Mantener en contacto a los actores que ya no tienen relación
          directa o intereses<br />
        </p>

        <br />

        <p>
          Datos sensibles Para el caso de datos personales sensibles,
          PRODUCTORA FLÓREZ Y CIA podrá hacer uso y tratamiento de ellos
          cuando:
        </p>
        <br />
        <p>
          1. El Titular haya dado su autorización explícita, salvo en los casos
          que por ley no sea requerido el otorgamiento de dicha autorización.<br />
          2. El tratamiento sea necesario para salvaguardar el interés vital del
          Titular y este se encuentre física o jurídicamente incapacitado. En
          estos eventos, los representantes legales deberán otorgar su
          autorización.<br />
          3. El tratamiento sea efectuado en el curso de las actividades
          legítimas y con las debidas garantías por parte de una fundación, ONG,
          asociación o cualquier otro organismo sin ánimo de lucro, cuya
          finalidad sea política, filosófica, religiosa o sindical, siempre que
          se refieran exclusivamente.<br />
          4. A las personas que mantengan contactos regulares por razón de su
          finalidad. En estos eventos, los datos no se podrán suministrar a
          terceros sin la autorización del Titular.<br />
          5. El Tratamiento se refiera a datos que sean necesarios para el
          reconocimiento, ejercicio o defensa de un derecho en un proceso.<br />
          6. El Tratamiento tenga una finalidad histórica, estadística o
          científica. En este evento deberán adoptarse las medidas conducentes a
          la supresión de identidad de los titulares. Sin perjuicio de las
          excepciones previstas en la ley, en el tratamiento de datos sensibles
          se requiere la autorización previa, expresa e informada del Titular,
          la cual deberá ser obtenida por cualquier medio que pueda ser objeto
          de consulta y verificación posterior.<br />
        </p>
        <br />
        <h2><span class="font-bold">X. AVISO DE PRIVACIDAD</span></h2>
        <br />
        <p>
          Somos una Empresa comprometida con la privacidad y la seguridad de los
          Datos Personales de nuestros CLIENTES, PROVEEDORES, EMPLEADOS Y
          USUARIOS en los términos de la Ley 1581 de 2012 y el Decreto 1377 de
          2013; en este sentido, se ha adoptado la siguiente ‘política de
          privacidad y protección de datos personales’, en la cual, nuestros
          Clientes, Proveedores, Empleados y Usuarios, podrán conocer el
          procedimiento y tratamiento de sus Datos Personales que ingresan en
          nuestras bases de datos, así como los derechos, garantías y los
          procedimientos para hacerlos efectivos.
        </p>
        <br />
        <p>
          A través de este documento se comunica al Titular la información
          relacionada con la existencia de las políticas de tratamiento de
          información de PRODUCTORA FLÓREZ Y CIA y que le serán aplicables,
          la forma de acceder a las mismas y las características del tratamiento
          que se pretende dar a los datos personales. El aviso de privacidad
          deberá contener, como mínimo, la siguiente información:
        </p>
        <br />
        <p>
          1. La identidad, domicilio y datos de contacto del responsable del tratamiento.
          <br />
          2. El tipo de tratamiento al cual serán sometidos los datos y la
          finalidad del tratamiento.<br />
          3. Los derechos del titular.<br />
          4. Los mecanismos generales dispuestos por el responsable para que el
          Titular conozca la política de tratamiento de la información y los
          cambios sustanciales que se produzcan en ella. En todos los casos,
          debe informar al Titular cómo acceder o consultar la política de
          tratamiento de información.<br />
          5. El carácter facultativo de la respuesta relativa a preguntas sobre
          datos.<br />
        </p>
        <br />

        <h2 class="font-bold">XI. GARANTÍAS DEL DERECHO DE ACCESO</h2>
        <br />
        <p>
          Para garantizar el derecho de acceso del Titular de los datos,
          PRODUCTORA FLÓREZ Y CIA pondrá a disposición de éste, previa
          acreditación de su identidad, legitimidad, o personalidad de su
          representante, sin costo o erogación alguna, de manera pormenorizada y
          detallada, los respectivos datos personales a través de todo tipo de
          medio, incluyendo los medios electrónicos que permitan el acceso
          directo del titular a ellos. Dicho acceso deberá ofrecerse sin límite
          alguno y le deben permitir al titular la posibilidad de conocerlos y
          actualizarlos en línea.
        </p>
        <br />
        <h2 class="font-bold">
          XII. PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS, PETICIONES
          DE RECTIFICACIÓN, ACTUALIZACIÓN Y SUPRESIÓN DE DATOS
        </h2>
        <br />
        <p>
          Consultas: Los titulares o sus causahabientes podrán consultar la
          información personal del titular que repose en PRODUCTORA FLÓREZ Y CIA,
          quien suministrará toda la información contenida en el registro
          individual o que esté vinculada con la identificación del Titular. Con
          respecto a la atención de solicitudes de consulta de datos personales
         PRODUCTORA FLÓREZ Y CIA garantiza:
        </p>
        <br />
        <p>
          1. Habilitar medios de comunicación electrónica u otros que
          considere.<br />
          2. Establecer formularios, sistemas y otros métodos simplificados, los
          cuales deberán ser informados en el aviso de privacidad.<br />
          3. Utilizar los servicios de atención al cliente o de reclamaciones
          que tiene en operación.<br />
          4. En cualquier caso, independientemente del mecanismo implementado
          para la atención de solicitudes de consulta, las mismas serán
          atendidas en un término máximo de quince (15) días hábiles contados a
          partir de la fecha de su recibo. Cuando no fuere posible atender la
          consulta dentro de dicho término, se informará al interesado antes del
          vencimiento de los 15 días, expresando los motivos de la demora y
          señalando la fecha en que se atenderá su consulta, la cual en ningún
          caso podrá superar los cinco (5) días hábiles siguientes al
          vencimiento del primer plazo.<br />
          5. Las consultas podrán formularse al correo
          contacto@juanabonita.com.co o mediante comunicación escrita a la calle
          57 sur # 48 – 24 interiores 103-104, en el Municipio de Sabaneta –
          Antioquia, dirigido al encargado de Servicio al Cliente.<br />
          6. Reclamos El Titular o sus causahabientes que consideren que la
          información contenida en una base de datos debe ser objeto de
          corrección, actualización o supresión, o cuando adviertan el presunto
          incumplimiento de cualquiera de los deberes contenidos en la ley,
          podrán presentar un reclamo ante PRODUCTORA FLÓREZ Y CIA, el cual
          será tramitado bajo las siguientes reglas:<br />
        </p>
        <br />
        <p>
          El reclamo del Titular se formulará mediante solicitud dirigida a
          PRODUCTORA FLÓREZ Y CIA al correo electrónico
          contacto@juanabonita.com.co o mediante comunicación escrita a la calle
          57 sur # 48 – 24 interiores 103-104, en el Municipio de Sabaneta –
          Antioquia, dirigido al encargado de servicio al Cliente, con la
          identificación del Titular, la descripción de los hechos que dan lugar
          al reclamo, la dirección, y acompañando los documentos que se quiera
          hacer valer. Si el reclamo resulta incompleto, se requerirá al
          interesado dentro de los cinco (5) días siguientes a la recepción del
          reclamo para que subsane las fallas. Transcurridos dos (2) meses desde
          la fecha del requerimiento, sin que el solicitante presente la
          información requerida, se entenderá que ha desistido del reclamo. En
          caso de que quien reciba el reclamo no sea competente para resolverlo,
          dará traslado a quien corresponda en un término máximo de dos (2) días
          hábiles e informará de la situación al interesado.
        </p>
        <br />
        <p>
          Una vez recibido el reclamo completo, éste se catalogará con la
          etiqueta «reclamo en trámite» y el motivo del mismo, en un término no
          mayor a dos (2) días hábiles. Dicha etiqueta se mantendrá hasta que el
          reclamo sea decidido.
        </p>
        <br />
        <p>
          El término máximo para atender el reclamo será de quince (15) días
          hábiles contados a partir del día siguiente a la fecha de su recibo.
          Cuando no fuere posible atender el reclamo dentro de dicho término, se
          informará al interesado los motivos de la demora y la fecha en que se
          atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8)
          días hábiles siguientes al vencimiento del primer término.
        </p>
        <br />
        <p>
          Petición de actualización y/o rectificación PRODUCTORA FLÓREZ Y CIA
          rectificará y actualizará, a solicitud del titular, la
          información de éste que resulte ser incompleta o inexacta, de
          conformidad con el procedimiento y los términos antes señalados, para
          lo cual se tendrá en cuenta:
        </p>
        <br />
        <p>
          El Titular deberá allegar la solicitud al correo electrónico
          contacto@juanabonita.com.co o mediante comunicación escrita a la calle
          57 sur #48–24 interiores 103-104, en el Municipio de
          Sabaneta–Antioquia, dirigido al encargado de Servicio al Cliente
          indicando la actualización y/o rectificación a realizar y aportará la
          documentación que sustente su petición.
        </p>
        <br />
        <p>
          PRODUCTORA FLÓREZ Y CIA podrá habilitar mecanismos que le
          faciliten el ejercicio de este derecho al titular, siempre y cuando
          éstos lo beneficien. En consecuencia, se podrán habilitar medios
          electrónicos u otros que considere pertinentes, los cuales serán
          informados en el aviso de privacidad y se pondrán a disposición de los
          interesados.
        </p>
        <br />
        <p>
          1. Petición de supresión de datos El titular de los datos personales
          tiene el derecho de solicitar a PRODUCTORA FLÓREZ Y CIA su
          supresión (eliminación) en cualquiera de los siguientes eventos:<br />
          2. Considere que los mismos no están siendo tratados conforme a los
          principios, deberes y obligaciones previstas en la normatividad
          vigente.<br />
          3. Hayan dejado de ser necesarios o pertinentes para la finalidad para
          la cual fueron entregados.<br />
          4. Se haya superado el periodo necesario para el cumplimiento de los
          fines para los que fueron entregados.<br />
        </p>
        <br />
        <p>
          Esta supresión implica la eliminación total o parcial de la
          información personal de acuerdo con lo solicitado por el titular en
          los registros, archivos, bases de datos o tratamientos realizados por
          PRODUCTORA FLÓREZ Y CIA Sin embargo este derecho del titular no
          es absoluto y en consecuencia PRODUCTORA FLÓREZ Y CIA podrá negar
          el ejercicio del mismo cuando:
        </p>
        <br />
        <p>
          1. El titular tenga un deber legal o contractual de permanecer en la
          base de datos.<br />
          2. La eliminación de datos obstaculice actuaciones judiciales o
          administrativas vinculadas a obligaciones fiscales, la investigación y
          persecución de delitos o la actualización de sanciones.<br />
          3. Los datos sean necesarios para proteger los intereses jurídicamente
          tutelados del titular; para realizar una acción en función del interés
          público, o para cumplir con una obligación legalmente adquirida por el
          Titular.<br />
        </p>
        <br />
        <h2 class="font-bold">XIII. REGISTRO NACIONAL DE BASE DE DATOS</h2>
        <br />
        <p>
          PRODUCTORA FLÓREZ Y CIA, se reserva, en los eventos contemplados
          en la ley y en sus estatutos y reglamentos internos, la facultad de
          mantener y catalogar determinada información que repose en sus bases o
          bancos de datos, como confidencial de acuerdo con las normas vigentes,
          sus estatutos y reglamentos, todo lo anterior y en consonancia su
          autonomía.
        </p>
        <br />
        <p>
          PRODUCTORA FLÓREZ Y CIA, procederá de acuerdo con la normatividad
          vigente y la reglamentación que para tal fin expida el Gobierno
          Nacional, a realizar el Registro de sus bases de datos, ante El
          Registro Nacional de Bases de Datos (RNBD) que será administrado por
          la Superintendencia de Industria y Comercio. El RNBD., es el
          directorio público de las bases de datos sujetas a Tratamiento que
          operan en el país; y que será de libre consulta para los ciudadanos,
          de acuerdo con la normatividad que para tal efecto expida el Gobierno
          Nacional.
        </p>
        <br />
        <h2 class="font-bold">
          XIV. SEGURIDAD DE LA INFORMACIÓN Y MEDIDAS DE SEGURIDAD
        </h2>
        <br />
        <p>
          Dando cumplimiento al principio de seguridad establecido en la
          normatividad vigente, PRODUCTORA FLÓREZ Y CIA adoptará las
          medidas técnicas, humanas y administrativas que sean necesarias para
          otorgar seguridad a los registros evitando su adulteración, pérdida,
          consulta, uso o acceso no autorizado o fraudulento.
        </p>
        <br />
        <h2 class="font-bold">
          XV. UTILIZACIÓN Y TRANSFERENCIA INTERNACIONAL DE DATOS PERSONALES E
          INFORMACIÓN PERSONAL POR PARTE DE PRODUCTORA FLÓREZ Y CIA
        </h2>
        <br />
        <p>
          En cumplimiento de la misión institucional y del plan estratégico de
          desarrollo de PRODUCTORA FLÓREZ Y CIA, y atendiendo a la
          naturaleza de las relaciones permanentes u ocasionales que cualquier
          persona titular de datos personales pueda tener para con PRODUCTORA FLÓREZ Y CIA,
          ésta podrá realizar la transferencia y transmisión,
          incluso internacional, de la totalidad de los datos personales,
          siempre y cuando se cumplan los requerimientos legales aplicables; y
          en consecuencia los titulares con la aceptación de la presente
          política, autorizan expresamente para transferir y transmitir, incluso
          a nivel internacional, los datos personales. Los datos serán
          transferidos, para todas las relaciones que puedan establecerse con
          PRODUCTORA FLÓREZ Y CIA Para la transferencia internacional de
          datos personales de los titulares, PRODUCTORA FLÓREZ Y CIA tomará
          las medidas necesarias para que los terceros conozcan y se comprometan
          a observar la presente política, bajo el entendido que la información
          personal que reciban, únicamente podrá ser utilizada para asuntos
          directamente relacionados con PRODUCTORA FLÓREZ Y CIA y solamente
          mientras ésta dure y no podrá ser usada o destinada para propósito o
          fin diferente. Para la transferencia internacional de datos personales
          se observará lo previsto en el artículo 26 de la Ley 1581 de 2012. Las
          transmisiones internacionales de datos personales que efectúen
          PRODUCTORA FLÓREZ Y CIA, no requerirán ser informadas al titular
          ni contar con su consentimiento cuando medie un contrato de
          transmisión de datos personales de conformidad al artículo 25 del
          Decreto 1377 de 2013. PRODUCTORA FLÓREZ Y CIA, también podrá
          intercambiar información personal con autoridades gubernamentales o
          públicas de otro tipo (incluidas, entre otras autoridades judiciales o
          administrativas, autoridades fiscales y organismos de investigación
          penal, civil, administrativa, disciplinaria y fiscal), y terceros
          participantes en procedimientos legales civiles y sus contadores,
          auditores, abogados y otros asesores y representantes, porque es
          necesario o apropiado: (a) para cumplir con las leyes vigentes,
          incluidas las leyes distintas a las de su país de residencia; (b) para
          cumplir con procesos jurídicos; (c) para responder las solicitudes de
          las autoridades públicas y del gobierno, y para responder las
          solicitudes de las autoridades públicas y del gobierno distintas a las
          de su país de residencia; (d) para hacer cumplir nuestros términos y
          condiciones; (e) para proteger nuestras operaciones; (f) para proteger
          nuestros derechos, privacidad, seguridad o propiedad, los suyos o los
          de terceros; y (g) obtener las indemnizaciones aplicables o limitar
          los daños y perjuicios que nos puedan afectar.
        </p>

        <br />

        <h2 class="font-bold">
          XVI. RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS PERSONALES
        </h2>
        <br />
        <p>
          PRODUCTORA FLÓREZ Y CIA será la responsable del tratamiento de
          los datos personales.
        </p>
        <br />
        <h2 class="font-bold">XVII. VIGENCIA</h2>
        <br />
        <p>
          La presente política rige a  partir del 21 de marzo de 2024 y deja sin efectos los reglamentos o manuales especiales que se hubiesen podido adoptar por instancias académicas y/o administrativas en sus instalaciones. Así mismo las políticas con anterior vigencia.
        </p>
        <br />
        <p>
          PRODUCTORA FLOREZ Y CIA SAS<br />
          CL 76 45 A 166 IN 201
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    //HelloWorld
  },
};
</script>

<style scoped>
#politica {
  text-align: center;
}
</style>
